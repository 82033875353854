export const logoNegative = ['608 134', ` 
<svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 608 134" class="c-icon c-icon-custom-size c-sidebar-brand-full" role="img"> 
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="100%" viewBox="0 0 476 146" enable-background="new 0 0 476 146" xml:space="preserve">
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M304.000000,147.000000 
C202.666702,147.000000 101.833397,147.000000 1.000068,147.000000 
C1.000045,98.333382 1.000045,49.666756 1.000023,1.000100 
C159.666580,1.000067 318.333160,1.000067 476.999817,1.000033 
C476.999878,49.666561 476.999878,98.333122 476.999939,146.999847 
C419.500000,147.000000 362.000000,147.000000 304.000000,147.000000 
M132.930328,16.088161 
C132.930328,16.088161 132.774811,16.091578 132.528610,15.514688 
C131.061707,14.586903 129.594818,13.659116 127.809837,12.105557 
C126.745270,11.296536 125.680702,10.487515 124.152039,9.097853 
C122.474998,8.677401 120.797958,8.256948 118.391380,7.415780 
C90.167908,7.346870 61.944439,7.274074 33.720928,7.234486 
C33.101101,7.233616 32.480743,7.612132 31.099504,7.707938 
C29.530195,8.370864 27.960886,9.033789 25.960466,9.719905 
C25.606495,9.959623 25.252522,10.199342 24.150219,10.436790 
C23.539742,10.833979 22.929264,11.231168 21.704964,11.860895 
C12.031611,19.532171 10.625627,29.935642 10.873285,41.491787 
C11.353344,63.892132 10.990442,86.310074 11.051900,108.720512 
C11.058635,111.176315 11.556013,113.630768 11.619878,116.598373 
C11.837382,117.077934 12.054887,117.557503 12.519638,118.760910 
C15.989281,127.237442 21.511215,133.376328 31.922539,135.196045 
C61.279377,135.127045 90.636215,135.058060 120.732780,135.141083 
C129.368851,132.555298 135.529648,127.361282 138.722580,118.339386 
C138.847275,117.549118 138.971970,116.758858 139.312805,115.226410 
C139.334015,114.201843 139.355225,113.177269 139.840942,111.615974 
C139.906479,85.149132 139.978439,58.682293 140.005585,32.215405 
C140.006378,31.442680 139.533905,30.669464 139.399368,29.126966 
C138.957230,27.413586 138.515106,25.700207 138.071808,23.294264 
C137.199097,21.796865 136.326385,20.299465 135.315796,18.327143 
C135.315796,18.327143 134.942123,18.003204 134.891190,17.414541 
C134.228088,17.023388 133.564972,16.632235 132.930328,16.088161 
M380.569305,96.180618 
C380.569305,96.180618 380.636414,96.239861 380.950348,96.882080 
C385.233429,100.734612 387.434692,97.854843 390.207886,93.809883 
C390.224030,87.229362 390.240173,80.648834 390.656555,73.524208 
C392.995148,68.171356 396.166809,66.104538 400.765198,66.936859 
C405.129333,67.726768 407.491760,71.151115 407.562103,76.971245 
C407.626068,82.263435 407.222382,87.597260 407.792664,92.829033 
C408.010315,94.825783 410.128143,96.883049 411.881958,98.284691 
C412.421387,98.715843 414.624451,97.065422 416.746246,96.131935 
C417.013214,95.287270 417.280212,94.442612 418.097412,93.032494 
C417.917084,86.157440 417.736786,79.282379 417.665131,71.683548 
C417.527252,70.466309 417.389343,69.249069 417.571320,67.514229 
C415.752594,56.626621 400.040100,52.917831 392.035950,59.865105 
C389.022247,62.480854 387.519623,62.978855 385.074066,59.979656 
C384.578644,59.372131 383.728516,59.034264 383.008026,58.632923 
C375.703369,54.563892 368.978973,56.301956 363.742004,60.282841 
C360.543945,58.977768 358.295654,57.577938 355.934723,57.351208 
C355.076324,57.268776 353.949249,59.984425 352.031921,61.817783 
C351.960907,72.591057 351.889923,83.364326 351.703003,94.854454 
C355.466278,99.733620 357.892578,99.929245 361.912781,95.062134 
C362.072327,87.684799 362.231903,80.307465 362.811066,72.316399 
C368.238861,64.004295 375.112030,64.654533 379.748535,74.870590 
C379.746368,78.349548 379.609497,81.835152 379.780945,85.305534 
C379.943054,88.586174 380.429077,91.850807 380.279877,95.519211 
C380.362793,95.713089 380.445740,95.906967 380.569305,96.180618 
M339.841553,58.274601 
C339.208862,58.066380 338.576172,57.858158 337.338531,57.320213 
C336.513062,57.863388 335.687561,58.406559 334.037170,58.997971 
C331.285736,61.241058 328.470123,60.353912 326.013855,58.718224 
C321.762085,55.886875 317.272522,55.989540 312.816376,58.340919 
C304.393707,62.785286 301.302887,69.419853 301.393951,78.879829 
C301.475037,87.298347 304.977844,93.009132 311.603882,97.134575 
C317.375397,100.727974 323.242096,99.890587 329.013245,96.478806 
C330.144867,95.809814 332.081879,96.503174 333.931488,97.164986 
C339.347504,99.766235 342.580353,98.146439 342.665802,92.498474 
C342.811890,82.845009 342.824310,73.184761 342.585022,63.535000 
C342.546051,61.964260 340.923798,60.432785 339.841553,58.274601 
M462.003754,81.442345 
C462.003754,81.442345 462.164062,81.222229 462.864899,81.018890 
C467.163757,72.438347 458.489899,59.249020 449.876404,57.036137 
C442.248413,55.076435 433.063171,59.082844 429.028564,65.123314 
C423.994446,72.660202 423.501038,83.334602 428.715637,90.435104 
C432.502350,95.591324 436.857544,100.110565 444.454498,97.410522 
C446.151245,96.807472 448.438049,97.988586 450.235016,97.516983 
C453.674835,96.614250 457.052704,95.299248 460.210999,93.677483 
C460.724823,93.413620 460.429962,90.415359 459.673462,89.201698 
C459.061310,88.219566 457.165436,87.508080 455.886719,87.575951 
C452.775879,87.741089 449.705200,88.695969 446.595398,88.840996 
C442.476990,89.033073 438.718109,88.129395 436.921814,82.558197 
C442.153534,82.558197 446.880432,82.695221 451.594879,82.512131 
C454.982422,82.380562 458.353363,81.821526 462.003754,81.442345 
M249.406937,81.600311 
C249.712738,81.211456 250.018539,80.822601 250.980988,80.065742 
C251.223923,79.456985 251.636230,78.861267 251.684250,78.237511 
C252.345947,69.641167 246.189499,59.810570 238.240250,57.455929 
C227.696167,54.332680 221.583328,58.217300 215.550034,66.387779 
C210.836853,72.770554 211.765015,85.691261 216.742233,91.224846 
C222.933395,98.108086 236.952530,101.037018 245.300247,96.665970 
C246.887650,95.834770 248.114578,92.624626 247.902771,90.687592 
C247.777527,89.542252 244.542160,88.045273 242.636932,87.939560 
C239.329575,87.756035 235.962006,88.875053 232.632904,88.806519 
C229.017365,88.732086 225.371948,87.982300 225.048676,82.558342 
C228.757248,82.558342 232.227295,82.685249 235.684204,82.526505 
C240.092911,82.324051 244.491028,81.890732 249.406937,81.600311 
M174.826187,77.257263 
C172.246109,81.320976 169.507172,85.294304 167.129700,89.473289 
C164.649597,93.832687 166.432846,98.332436 170.935150,98.473030 
C181.403473,98.799965 191.896759,98.795677 202.357941,98.376678 
C203.924637,98.313934 206.734924,95.108116 206.545563,93.677139 
C206.283539,91.697189 203.934113,88.772453 202.103928,88.462173 
C197.573746,87.694130 192.830322,88.208267 188.173248,88.152138 
C186.316116,88.129753 184.460739,87.962311 181.619568,87.807091 
C185.102280,82.334312 187.931351,77.871750 190.777451,73.420052 
C195.701050,65.718826 200.755615,58.097935 205.520554,50.299938 
C208.460648,45.488396 206.764435,40.494606 202.045242,40.329063 
C192.076569,39.979362 182.075134,39.968987 172.117966,40.453293 
C170.402115,40.536755 167.530304,43.782745 167.576797,45.503460 
C167.622162,47.182854 170.519333,49.136913 172.552277,50.282082 
C173.819290,50.995808 175.805573,50.439636 177.469070,50.441154 
C181.858582,50.445168 186.248093,50.442516 191.969513,50.442516 
C185.866776,60.004086 180.551117,68.332489 174.826187,77.257263 
M276.133942,84.441277 
C275.628174,83.843414 274.950836,83.322441 274.643188,82.635826 
C271.527832,75.682213 268.528534,68.676270 265.377441,61.739162 
C263.814117,58.297478 261.419525,55.494171 257.269501,57.489532 
C253.485611,59.308853 253.719971,62.839283 255.192383,66.150620 
C258.624634,73.869522 261.804749,81.742859 265.859741,89.129425 
C269.219940,95.250328 270.612061,100.743736 266.409515,106.981827 
C264.220673,110.230835 265.640564,113.131393 269.031311,114.749855 
C272.509460,116.410027 274.816833,114.450996 276.311249,111.563103 
C278.068695,108.166862 279.499237,104.601997 281.087341,101.117462 
C286.509552,89.220436 291.925629,77.320587 297.373871,65.435493 
C298.849243,62.217030 298.307526,59.549812 295.250458,57.689491 
C291.612152,55.475494 289.210754,57.798439 287.532379,60.511864 
C285.892792,63.162563 284.954620,66.242477 283.658997,69.111603 
C281.397522,74.119736 279.098022,79.110695 276.133942,84.441277 
z"></path>
<path fill="#333A40" opacity="1.000000" stroke="none" d="
M11.825825,116.085846 
C11.556013,113.630768 11.058635,111.176315 11.051900,108.720512 
C10.990442,86.310074 11.353344,63.892132 10.873285,41.491787 
C10.625627,29.935642 12.031611,19.532171 22.410717,11.795930 
C23.710497,11.300329 24.304523,10.869695 24.898548,10.439060 
C25.252522,10.199342 25.606495,9.959623 26.584568,9.741637 
C28.663548,9.625066 30.118427,9.486761 32.057186,9.236382 
C60.545448,9.086512 88.549835,9.044440 116.554222,9.028715 
C117.534187,9.028165 118.514305,9.289900 119.694801,9.532122 
C121.468880,9.649403 123.042511,9.663950 124.616135,9.678495 
C125.680702,10.487515 126.745270,11.296536 128.145203,12.633443 
C129.911972,14.138080 131.343384,15.114830 132.774811,16.091579 
C132.774811,16.091578 132.930328,16.088161 132.964600,16.469143 
C133.646622,17.234482 134.294373,17.618841 134.942123,18.003202 
C134.942123,18.003204 135.315796,18.327143 135.101868,18.730944 
C135.949615,20.752106 137.011292,22.369469 138.072968,23.986830 
C138.515106,25.700207 138.957230,27.413586 139.020721,29.797581 
C138.452774,31.427130 138.099365,32.385857 138.098160,33.345024 
C138.067108,58.268509 138.052383,83.192101 138.127518,108.115395 
C138.131561,109.462402 138.941605,110.806984 139.376434,112.152702 
C139.355225,113.177269 139.334015,114.201843 138.936249,115.837112 
C138.452301,117.141479 138.344894,117.835144 138.237503,118.528809 
C135.529648,127.361282 129.368851,132.555298 120.090210,134.745026 
C118.798538,134.234543 118.149513,134.020493 117.500336,134.020050 
C89.884598,134.001297 62.268826,133.988998 34.653175,134.041504 
C33.436741,134.043808 32.221474,134.660065 31.005665,134.990326 
C21.511215,133.376328 15.989281,127.237442 12.730768,118.347336 
C12.569875,117.317787 12.197850,116.701820 11.825825,116.085846 
M89.835793,29.905375 
C88.557121,29.663794 87.249779,29.111952 86.004059,29.227034 
C66.409142,31.037249 51.132748,40.152306 40.818100,56.927662 
C35.289207,65.919640 32.556629,75.892265 33.451923,87.362801 
C40.484940,87.575569 47.517139,87.876266 54.551525,87.941551 
C56.395218,87.958664 58.245689,87.245247 60.786148,87.392799 
C60.883461,96.187683 60.980778,104.982559 61.177464,114.599983 
C65.728767,117.315781 73.357437,116.152267 76.572578,111.873650 
C95.786072,105.698692 109.432076,93.628098 115.234589,73.992096 
C116.803848,68.681641 119.636818,62.914322 116.148216,56.884617 
C111.951973,56.884617 107.795898,57.005882 103.650162,56.850811 
C99.431595,56.693016 95.223206,56.263073 91.596939,55.366886 
C91.724861,47.699863 91.894043,40.032963 91.917076,32.365627 
C91.919380,31.596716 91.035477,30.825142 89.835793,29.905375 
z"></path>
<path fill="#3B4146" opacity="1.000000" stroke="none" d="
M416.065735,96.368149 
C414.624451,97.065422 412.421387,98.715843 411.881958,98.284691 
C410.128143,96.883049 408.010315,94.825783 407.792664,92.829033 
C407.222382,87.597260 407.626068,82.263435 407.562103,76.971245 
C407.491760,71.151115 405.129333,67.726768 400.765198,66.936859 
C396.166809,66.104538 392.995148,68.171356 390.034485,73.829453 
C388.936646,75.236618 388.002258,76.364075 388.061920,77.436203 
C388.374573,83.053268 388.939117,88.656319 389.416138,94.264236 
C387.434692,97.854843 385.233429,100.734612 381.005005,96.407059 
C381.108337,95.810883 381.156952,95.689728 381.205566,95.568573 
C380.979950,95.746002 380.754303,95.923431 380.528687,96.100861 
C380.445740,95.906967 380.362793,95.713089 380.625000,94.853729 
C380.994354,91.068878 381.182373,87.939919 381.000122,84.832664 
C380.786407,81.188194 380.250702,77.562599 379.854675,73.928818 
C375.112030,64.654533 368.238861,64.004295 362.273804,72.805923 
C361.605560,80.740875 361.474579,88.186310 361.343567,95.631737 
C357.892578,99.929245 355.466278,99.733620 352.108887,94.230804 
C352.658112,82.879250 352.801453,72.151344 352.944794,61.423439 
C353.949249,59.984425 355.076324,57.268776 355.934723,57.351208 
C358.295654,57.577938 360.543945,58.977768 363.742004,60.282841 
C368.978973,56.301956 375.703369,54.563892 383.008026,58.632923 
C383.728516,59.034264 384.578644,59.372131 385.074066,59.979656 
C387.519623,62.978855 389.022247,62.480854 392.035950,59.865105 
C400.040100,52.917831 415.752594,56.626621 417.024109,67.965027 
C416.339874,77.733261 416.202789,87.050697 416.065735,96.368149 
z"></path>
<path fill="#11CE9C" opacity="1.000000" stroke="none" d="
M175.030823,76.959076 
C180.551117,68.332489 185.866776,60.004086 191.969513,50.442516 
C186.248093,50.442516 181.858582,50.445168 177.469070,50.441154 
C175.805573,50.439636 173.819290,50.995808 172.552277,50.282082 
C170.519333,49.136913 167.622162,47.182854 167.576797,45.503460 
C167.530304,43.782745 170.402115,40.536755 172.117966,40.453293 
C182.075134,39.968987 192.076569,39.979362 202.045242,40.329063 
C206.764435,40.494606 208.460648,45.488396 205.520554,50.299938 
C200.755615,58.097935 195.701050,65.718826 190.777451,73.420052 
C187.931351,77.871750 185.102280,82.334312 181.619568,87.807091 
C184.460739,87.962311 186.316116,88.129753 188.173248,88.152138 
C192.830322,88.208267 197.573746,87.694130 202.103928,88.462173 
C203.934113,88.772453 206.283539,91.697189 206.545563,93.677139 
C206.734924,95.108116 203.924637,98.313934 202.357941,98.376678 
C191.896759,98.795677 181.403473,98.799965 170.935150,98.473030 
C166.432846,98.332436 164.649597,93.832687 167.129700,89.473289 
C169.507172,85.294304 172.246109,81.320976 175.030823,76.959076 
z"></path>
<path fill="#393E44" opacity="1.000000" stroke="none" d="
M276.474182,84.275063 
C279.098022,79.110695 281.397522,74.119736 283.658997,69.111603 
C284.954620,66.242477 285.892792,63.162563 287.532379,60.511864 
C289.210754,57.798439 291.612152,55.475494 295.250458,57.689491 
C298.307526,59.549812 298.849243,62.217030 297.373871,65.435493 
C291.925629,77.320587 286.509552,89.220436 281.087341,101.117462 
C279.499237,104.601997 278.068695,108.166862 276.311249,111.563103 
C274.816833,114.450996 272.509460,116.410027 269.031311,114.749855 
C265.640564,113.131393 264.220673,110.230835 266.409515,106.981827 
C270.612061,100.743736 269.219940,95.250328 265.859741,89.129425 
C261.804749,81.742859 258.624634,73.869522 255.192383,66.150620 
C253.719971,62.839283 253.485611,59.308853 257.269501,57.489532 
C261.419525,55.494171 263.814117,58.297478 265.377441,61.739162 
C268.528534,68.676270 271.527832,75.682213 274.643188,82.635826 
C274.950836,83.322441 275.628174,83.843414 276.474182,84.275063 
z"></path>
<path fill="#383E44" opacity="1.000000" stroke="none" d="
M248.893707,81.556923 
C244.491028,81.890732 240.092911,82.324051 235.684204,82.526505 
C232.227295,82.685249 228.757248,82.558342 225.048676,82.558342 
C225.371948,87.982300 229.017365,88.732086 232.632904,88.806519 
C235.962006,88.875053 239.329575,87.756035 242.636932,87.939560 
C244.542160,88.045273 247.777527,89.542252 247.902771,90.687592 
C248.114578,92.624626 246.887650,95.834770 245.300247,96.665970 
C236.952530,101.037018 222.933395,98.108086 216.742233,91.224846 
C211.765015,85.691261 210.836853,72.770554 215.550034,66.387779 
C221.583328,58.217300 227.696167,54.332680 238.240250,57.455929 
C246.189499,59.810570 252.345947,69.641167 251.684250,78.237511 
C251.636230,78.861267 251.223923,79.456985 250.405121,79.960464 
C241.921829,79.855194 234.014404,79.855194 226.106979,79.855194 
C226.110474,80.422440 226.113953,80.989677 226.117447,81.556923 
C233.709534,81.556923 241.301620,81.556923 248.893707,81.556923 
M230.814499,66.867050 
C227.381470,67.681252 224.927765,69.403572 224.551422,73.604019 
C229.665070,73.604019 234.495972,73.604019 239.901810,73.604019 
C238.818253,68.744133 236.034271,66.808174 230.814499,66.867050 
z"></path>
<path fill="#383E43" opacity="1.000000" stroke="none" d="
M461.731689,81.453186 
C458.353363,81.821526 454.982422,82.380562 451.594879,82.512131 
C446.880432,82.695221 442.153534,82.558197 436.921814,82.558197 
C438.718109,88.129395 442.476990,89.033073 446.595398,88.840996 
C449.705200,88.695969 452.775879,87.741089 455.886719,87.575951 
C457.165436,87.508080 459.061310,88.219566 459.673462,89.201698 
C460.429962,90.415359 460.724823,93.413620 460.210999,93.677483 
C457.052704,95.299248 453.674835,96.614250 450.235016,97.516983 
C448.438049,97.988586 446.151245,96.807472 444.454498,97.410522 
C436.857544,100.110565 432.502350,95.591324 428.715637,90.435104 
C423.501038,83.334602 423.994446,72.660202 429.028564,65.123314 
C433.063171,59.082844 442.248413,55.076435 449.876404,57.036137 
C458.489899,59.249020 467.163757,72.438347 462.258484,80.760574 
C453.768951,80.502258 445.885864,80.502258 438.002808,80.502258 
C437.999664,80.819229 437.996521,81.136208 437.993378,81.453178 
C445.906158,81.453178 453.818909,81.453178 461.731689,81.453186 
M450.821350,73.770836 
C451.244598,73.170181 452.155396,72.401077 452.015106,71.995125 
C450.717773,68.240265 447.820953,66.468109 444.012238,66.892189 
C440.300476,67.305481 437.223907,68.866577 437.242126,73.853477 
C441.747528,73.853477 445.847382,73.853477 450.821350,73.770836 
z"></path>
<path fill="#373D43" opacity="1.000000" stroke="none" d="
M333.647430,96.568192 
C332.081879,96.503174 330.144867,95.809814 329.013245,96.478806 
C323.242096,99.890587 317.375397,100.727974 311.603882,97.134575 
C304.977844,93.009132 301.475037,87.298347 301.393951,78.879829 
C301.302887,69.419853 304.393707,62.785286 312.816376,58.340919 
C317.272522,55.989540 321.762085,55.886875 326.013855,58.718224 
C328.470123,60.353912 331.285736,61.241058 334.739014,59.080597 
C336.275024,58.658791 337.109253,58.154358 337.943481,57.649933 
C338.576172,57.858158 339.208862,58.066380 339.916107,59.054691 
C339.990662,71.669472 339.990662,83.504166 339.990662,95.235100 
C337.503632,95.757774 335.575531,96.162987 333.647430,96.568192 
M329.381592,85.832512 
C333.675323,79.565384 332.772888,71.600540 327.386108,68.220413 
C323.384644,65.709564 318.096405,66.430389 315.178772,69.884361 
C311.088257,74.726830 311.179932,81.367294 315.404541,86.236336 
C318.529480,89.837952 323.730042,89.903412 329.381592,85.832512 
z"></path>
<path fill="#3F4B52" opacity="1.000000" stroke="none" d="
M139.608704,111.884338 
C138.941605,110.806984 138.131561,109.462402 138.127518,108.115395 
C138.052383,83.192101 138.067108,58.268509 138.098160,33.345024 
C138.099365,32.385857 138.452774,31.427130 138.962067,30.182337 
C139.533905,30.669464 140.006378,31.442680 140.005585,32.215405 
C139.978439,58.682293 139.906479,85.149132 139.608704,111.884338 
z"></path>
<path fill="#000002" opacity="1.000000" stroke="none" d="
M119.494347,9.429388 
C118.514305,9.289900 117.534187,9.028165 116.554222,9.028715 
C88.549835,9.044440 60.545448,9.086512 32.209522,8.863113 
C31.872198,8.339170 31.866415,8.076422 31.860634,7.813672 
C32.480743,7.612132 33.101101,7.233616 33.720928,7.234486 
C61.944439,7.274074 90.167908,7.346870 118.763763,8.044457 
C119.255547,8.925219 119.374947,9.177303 119.494347,9.429388 
z"></path>
<path fill="#1E252C" opacity="1.000000" stroke="none" d="
M333.789459,96.866592 
C335.575531,96.162987 337.503632,95.757774 339.990662,95.235100 
C339.990662,83.504166 339.990662,71.669472 340.012024,59.358971 
C340.923798,60.432785 342.546051,61.964260 342.585022,63.535000 
C342.824310,73.184761 342.811890,82.845009 342.665802,92.498474 
C342.580353,98.146439 339.347504,99.766235 333.789459,96.866592 
z"></path>
<path fill="#000000" opacity="1.000000" stroke="none" d="
M31.464102,135.093185 
C32.221474,134.660065 33.436741,134.043808 34.653175,134.041504 
C62.268826,133.988998 89.884598,134.001297 117.500336,134.020050 
C118.149513,134.020493 118.798538,134.234543 119.720345,134.669006 
C90.636215,135.058060 61.279377,135.127045 31.464102,135.093185 
z"></path>
<path fill="#55595E" opacity="1.000000" stroke="none" d="
M361.628174,95.346939 
C361.474579,88.186310 361.605560,80.740875 362.064026,73.112793 
C362.231903,80.307465 362.072327,87.684799 361.628174,95.346939 
z"></path>
<path fill="#16222C" opacity="1.000000" stroke="none" d="
M389.812012,94.037064 
C388.939117,88.656319 388.374573,83.053268 388.061920,77.436203 
C388.002258,76.364075 388.936646,75.236618 389.834351,74.101501 
C390.240173,80.648834 390.224030,87.229362 389.812012,94.037064 
z"></path>
<path fill="#3B4146" opacity="1.000000" stroke="none" d="
M417.556488,72.407326 
C417.736786,79.282379 417.917084,86.157440 417.609375,92.839844 
C417.266418,85.900574 417.411469,79.153946 417.556488,72.407326 
z"></path>
<path fill="#000017" opacity="1.000000" stroke="none" d="
M352.488342,61.620613 
C352.801453,72.151344 352.658112,82.879250 352.166840,93.872375 
C351.889923,83.364326 351.960907,72.591057 352.488342,61.620613 
z"></path>
<path fill="#000000" opacity="1.000000" stroke="none" d="
M417.610809,72.045441 
C417.411469,79.153946 417.266418,85.900574 417.334290,93.122574 
C417.280212,94.442612 417.013214,95.287270 416.406006,96.250038 
C416.202789,87.050697 416.339874,77.733261 416.864197,68.223816 
C417.389343,69.249069 417.527252,70.466309 417.610809,72.045441 
z"></path>
<path fill="#040F1D" opacity="1.000000" stroke="none" d="
M249.150330,81.578613 
C241.301620,81.556923 233.709534,81.556923 226.117447,81.556923 
C226.113953,80.989677 226.110474,80.422440 226.106979,79.855194 
C234.014404,79.855194 241.921829,79.855194 250.076797,80.144470 
C250.018539,80.822601 249.712738,81.211456 249.150330,81.578613 
z"></path>
<path fill="#000C1C" opacity="1.000000" stroke="none" d="
M461.867737,81.447762 
C453.818909,81.453178 445.906158,81.453178 437.993378,81.453178 
C437.996521,81.136208 437.999664,80.819229 438.002808,80.502258 
C445.885864,80.502258 453.768951,80.502258 461.908051,80.862244 
C462.164062,81.222229 462.003754,81.442345 461.867737,81.447762 
z"></path>
<path fill="#12202A" opacity="1.000000" stroke="none" d="
M379.801605,74.399704 
C380.250702,77.562599 380.786407,81.188194 381.000122,84.832664 
C381.182373,87.939919 380.994354,91.068878 380.871399,94.655357 
C380.429077,91.850807 379.943054,88.586174 379.780945,85.305534 
C379.609497,81.835152 379.746368,78.349548 379.801605,74.399704 
z"></path>
<path fill="#3F4B52" opacity="1.000000" stroke="none" d="
M138.072388,23.640549 
C137.011292,22.369469 135.949615,20.752106 135.170807,18.968405 
C136.326385,20.299465 137.199097,21.796865 138.072388,23.640549 
z"></path>
<path fill="#3F4B52" opacity="1.000000" stroke="none" d="
M132.651703,15.803135 
C131.343384,15.114830 129.911972,14.138080 128.304230,12.946329 
C129.594818,13.659116 131.061707,14.586903 132.651703,15.803135 
z"></path>
<path fill="#4F5052" opacity="1.000000" stroke="none" d="
M31.480068,7.760805 
C31.866415,8.076422 31.872198,8.339170 31.725643,8.975189 
C30.118427,9.486761 28.663548,9.625066 26.800121,9.730042 
C27.960886,9.033789 29.530195,8.370864 31.480068,7.760805 
z"></path>
<path fill="#4F5052" opacity="1.000000" stroke="none" d="
M119.694801,9.532122 
C119.374947,9.177303 119.255547,8.925219 119.128540,8.254815 
C120.797958,8.256948 122.474998,8.677401 124.384087,9.388175 
C123.042511,9.663950 121.468880,9.649403 119.694801,9.532122 
z"></path>
<path fill="#1E252C" opacity="1.000000" stroke="none" d="
M337.640991,57.485069 
C337.109253,58.154358 336.275024,58.658791 335.151428,59.056477 
C335.687561,58.406559 336.513062,57.863388 337.640991,57.485069 
z"></path>
<path fill="#3F4B52" opacity="1.000000" stroke="none" d="
M134.916656,17.708870 
C134.294373,17.618841 133.646622,17.234482 132.950378,16.545601 
C133.564972,16.632235 134.228088,17.023388 134.916656,17.708870 
z"></path>
<path fill="#6B6E70" opacity="1.000000" stroke="none" d="
M11.722852,116.342110 
C12.197850,116.701820 12.569875,117.317787 12.607145,117.985413 
C12.054887,117.557503 11.837382,117.077934 11.722852,116.342110 
z"></path>
<path fill="#3F4B52" opacity="1.000000" stroke="none" d="
M138.480042,118.434097 
C138.344894,117.835144 138.452301,117.141479 138.828186,116.208206 
C138.971970,116.758858 138.847275,117.549118 138.480042,118.434097 
z"></path>
<path fill="#4F5052" opacity="1.000000" stroke="none" d="
M24.524384,10.437925 
C24.304523,10.869695 23.710497,11.300329 22.717628,11.679661 
C22.929264,11.231168 23.539742,10.833979 24.524384,10.437925 
z"></path>
<path fill="#12202A" opacity="1.000000" stroke="none" d="
M380.549011,96.140739 
C380.754303,95.923431 380.979950,95.746002 381.205566,95.568573 
C381.156952,95.689728 381.108337,95.810883 380.848053,96.085953 
C380.636414,96.239861 380.569305,96.180618 380.549011,96.140739 
z"></path>
<path fill="#05CB99" opacity="1.000000" stroke="none" d="
M33.063805,86.586906 
C32.556629,75.892265 35.289207,65.919640 40.818100,56.927662 
C51.132748,40.152306 66.409142,31.037249 86.004059,29.227034 
C87.249779,29.111952 88.557121,29.663794 90.204819,30.444393 
C90.719353,39.306374 90.864861,47.629333 91.010361,55.952297 
C95.223206,56.263073 99.431595,56.693016 103.650162,56.850811 
C107.795898,57.005882 111.951973,56.884617 116.148216,56.884617 
C119.636818,62.914322 116.803848,68.681641 115.234589,73.992096 
C109.432076,93.628098 95.786072,105.698692 75.863571,112.028305 
C70.462410,112.714462 65.770256,113.245949 61.078094,113.777435 
C60.980778,104.982559 60.883461,96.187683 59.969631,87.043610 
C50.456676,86.658585 41.760242,86.622742 33.063805,86.586906 
M77.004951,66.176491 
C73.797272,65.818619 70.870071,66.249313 70.025688,69.955276 
C69.253487,73.344414 70.157478,76.319214 73.744766,77.593002 
C77.262398,78.842064 79.425957,76.706490 80.918678,73.921318 
C82.889252,70.244560 80.811310,68.082443 77.004951,66.176491 
z"></path>
<path fill="#342B3B" opacity="1.000000" stroke="none" d="
M61.127777,114.188705 
C65.770256,113.245949 70.462410,112.714462 75.553528,112.264740 
C73.357437,116.152267 65.728767,117.315781 61.127777,114.188705 
z"></path>
<path fill="#2F001B" opacity="1.000000" stroke="none" d="
M33.257866,86.974854 
C41.760242,86.622742 50.456676,86.658585 59.623077,86.780869 
C58.245689,87.245247 56.395218,87.958664 54.551525,87.941551 
C47.517139,87.876266 40.484940,87.575569 33.257866,86.974854 
z"></path>
<path fill="#33022F" opacity="1.000000" stroke="none" d="
M91.303650,55.659592 
C90.864861,47.629333 90.719353,39.306374 90.568359,30.519108 
C91.035477,30.825142 91.919380,31.596716 91.917076,32.365627 
C91.894043,40.032963 91.724861,47.699863 91.303650,55.659592 
z"></path>
<path fill="#DFE0E0" opacity="1.000000" stroke="none" d="
M231.218750,66.824249 
C236.034271,66.808174 238.818253,68.744133 239.901810,73.604019 
C234.495972,73.604019 229.665070,73.604019 224.551422,73.604019 
C224.927765,69.403572 227.381470,67.681252 231.218750,66.824249 
z"></path>
<path fill="#E0E1E1" opacity="1.000000" stroke="none" d="
M450.384277,73.812157 
C445.847382,73.853477 441.747528,73.853477 437.242126,73.853477 
C437.223907,68.866577 440.300476,67.305481 444.012238,66.892189 
C447.820953,66.468109 450.717773,68.240265 452.015106,71.995125 
C452.155396,72.401077 451.244598,73.170181 450.384277,73.812157 
z"></path>
<path fill="#F5F5F5" opacity="1.000000" stroke="none" d="
M329.119934,86.119095 
C323.730042,89.903412 318.529480,89.837952 315.404541,86.236336 
C311.179932,81.367294 311.088257,74.726830 315.178772,69.884361 
C318.096405,66.430389 323.384644,65.709564 327.386108,68.220413 
C332.772888,71.600540 333.675323,79.565384 329.119934,86.119095 
z"></path>
<path fill="#354246" opacity="1.000000" stroke="none" d="
M77.369202,66.311264 
C80.811310,68.082443 82.889252,70.244560 80.918678,73.921318 
C79.425957,76.706490 77.262398,78.842064 73.744766,77.593002 
C70.157478,76.319214 69.253487,73.344414 70.025688,69.955276 
C70.870071,66.249313 73.797272,65.818619 77.369202,66.311264 
z"></path>
</svg>
</svg>
`]
